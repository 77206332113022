import React from "react"
import { Box } from "rebass"

const ErrorHandling = () => (
  <Box mt={[32]}>
    <h2>DynamoDB Error Handling The Right Way</h2>
    <p>
      The rule of the thumb is to always wrap DynamoDB operations with{" "}
      <i>"try-catch"</i> and handle any operation failures gracefully.
      <br /><br />
      Apart from that, DynamoDB SDKs deal with many errors and retries
      internally. You don't have to write automatic retrying because that's already
      handled by AWS using an exponential backoff algorithm with jitter (randomized
      delay). <br />
      <br />
      If you want to customize your SDK behavior around retries, you can do that
      by providing custom ClientConfiguration.
    </p>
  </Box>
)

export default ErrorHandling
