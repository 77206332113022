import React from "react"
import { Container } from "@components"
import { Box, Flex } from "rebass"
import errors from "@constants/errors.js"
import { InternalLink } from "@fragments"
import ErrorCodes from "./ErrorCodes"
import ErrorHandling from "./ErrorHandling"

const Content = () => (
  <Container>
    <Box mt={[32, 62]}>
      <Flex flexDirection="column">
        <p>
          Common DynamoDB Errors is a compilation of some of the most common
          Amazon DynamoDB errors and how to fix them.
        </p>
        <ul>
          {errors
            .filter(e => e.solution && e.solution.length >= 1)
            .map((error, index) => (
              <li key={`error-${index + 1}`} style={{ marginBottom: 0 }} className="list-item-with-spacing">
                <InternalLink to={`/dynamodb-errors/${error.slug}/`}>
                  {error.error}
                </InternalLink>
              </li>
            ))}
        </ul>
      </Flex>
      <ErrorCodes />
      <ErrorHandling />
    </Box>
  </Container>
)

export default Content
