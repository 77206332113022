import React from "react"
import { FooterWithCTA as Footer, SEO, Layout, Hero } from "@components"
import Content from "@components/pages/errors/Content.js"
import SideCTA from "@components/SideCTA"

const ErrorsPage = () => {
  return (
    <>
      <Layout>
        <SEO
          title="Common DynamoDB Errors (and Solutions)"
          description="DynamoDB not working as expected? This page is a compilation of the most common DynamoDB user/system errors and how to fix them."
          canonical="https://dynobase.dev/dynamodb-errors/"
        />
        <div
          style={{
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Hero title="Common DynamoDB Errors" title2="(and Solutions)" />
          <Content />
        </div>
        <Footer />
      </Layout>
      <SideCTA />
    </>
  )
}

export default ErrorsPage
