import React from "react"
import { Box } from "rebass"

const ErrorCodes = () => (
  <Box mt={[32]}>
    <h2>List of DynamoDB Error Codes (and What They Mean)</h2>
    <p>
      DynamoDB Errors can be divided into two categories: <b>4xx</b>,{" "}
      <i>aka you did something wrong</i>, and <b>5xx</b>{" "}
      <i>aka problem is on AWS side.</i>
    </p>
    <h3>Error - Status Code 5xx - AWS Problem</h3>
    <p>
      5xx status code indicates a problem that must be resolved by Amazon. This
      might be a temporary error, in which case you can retry your request until
      it succeeds. You can also check the AWS Service Health Dashboard and see
      if there are any operational issues with the DynamoDB service in your
      region.
    </p>
    <h3>Error - Status Code 4xx - Problem with your request</h3>
    <p>
      Unlike 5xx, 4xx status code means that your request failed validation, was not permitted, or exceeded some limits. Some of the 4xx errors are thrown because of the temporary table state, e.g. it's not ready and they can be retried later. Below you can find a list of the most popular 4xx errors with a short explanation.
      <ul>
        <li>
          <b>AccessDeniedException</b> - The request was signed with incorrect
          signature
        </li>
        <li>
          <b>IncompleteSignatureException</b> - The request was signed with
          incomplete signature
        </li>
        <li>
          <b>LimitExceededException</b> - You exceeded the limit of 50 tables
          and indexes being in created, updated, or deleted
        </li>
        <li>
          <b>ResourceNotFoundException</b> - The request was made against a table
          that does not exist
        </li>
        <li>
          <b>ValidationException</b> - The request failed validation
        </li>
      </ul>
    </p>
  </Box>
)

export default ErrorCodes
